import React from "react"

import { Indent } from "../../../../uikit"
import { getModalClickHandler } from "src/components/ModalsContainer/events"

import * as S from "./styled"

const CTA = ({ headline, lead, btnName, url, modalType, modalTitle }) => {
  const toggleModal = getModalClickHandler(modalType, { title: modalTitle })

  if (!url && !modalType) {
    return ""
  }

  return (
    <S.Container id="cta">
      <Indent lg={120} md={80} sm={40} />
      <S.Box>
        <S.Headline>{headline}</S.Headline>
        <Indent lg={8} md={8} sm={16} />
        <S.Lead>{lead}</S.Lead>
        <Indent lg={16} md={16} sm={24} />
        {url && (
          <S.Link href={url} target="_blank" rel="noopener noreferrer">
            {btnName}
          </S.Link>
        )}
        {!url && modalType && (
          <S.Button type="button" onClick={toggleModal}>
            {btnName}
          </S.Button>
        )}
      </S.Box>
    </S.Container>
  )
}

export default CTA
