import styled, { css } from "styled-components"
import { Link as GLink } from "gatsby"

import { media } from "src/uikit"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Box = styled.div`
  max-width: 600px;
  width: 100%;
  background: #f7f7fc;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
`

export const Headline = styled.h3`
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
  letter-spacing: 0.8px;
  color: #142470;
  margin: 0;
  text-align: center;

  @media ${media.mobile} {
    font-size: 24px;
    line-height: 32px;
  }
`

export const Lead = styled.p`
  font-size: 15px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #001553;
  margin: 0;
`

const buttonCss = css`
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 15px;
  line-height: 16px;
  color: #ffffff;
  cursor: pointer;
  outline: none;
  border: none;
  font-family: "Montserrat", sans-serif;

  padding: 20px 28px;
  background-color: #5855f4;
  border-radius: 12px;

  :hover {
    background-color: #6f6cfa;
  }

  :active {
    background-color: #4b48f2;
  }
`

export const Link = styled.a`
  ${buttonCss}
`

export const Button = styled.button`
  ${buttonCss}
`

export const SlugLink = styled(GLink)`
  ${buttonCss}
`
